/* You can add global styles to this file, and also import other style files */

@import "assets/fontello/css/fontello.css";
@import "custom-theme.scss";

@font-face {
    font-family: "SedusSansWeb";
    font-weight: 700;
    src: url("assets/fonts/SedusSansWeb-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "SedusSansWeb";
    font-weight: 500;
    src: url("assets/fonts/SedusSansWeb-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "SedusSansWeb";
    font-weight: 400;
    src: url("assets/fonts/SedusSansWeb-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "SedusSansWeb";
    font-weight: 300;
    src: url("assets/fonts/SedusSansWeb-Light.woff2") format("woff2");
}

:root {
    --mainColor: #f7f7f7;
    --mainColorDark: #eeeeee;
    --borderColor: lightgrey;
    --space: 20px;
}

* {
    box-sizing: border-box;
    font-family: "SedusSansWeb", Arial, sans-serif;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    min-height: 100vh;
    margin: 0;
}

h1,
h2,
h3 {
    font-weight: 300;
    margin-bottom: 0;
}

h1 {
    font-size: 40px;
}

h1,
h2 {
    margin-top: 0;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: var(--space);
}

button {
    background-color: white;
    border: 1px solid var(--borderColor);
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
        background-color: var(--mainColor);
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.fixHeader {
    background-color: red;
    .kloeber {
        .order {
            position: fixed;
            z-index: 100;
            top: calc(var(--space) * 1.5);
            right: 70px;
            height: 42px !important;
            background-color: white !important;
            border: 1px solid var(--borderColor) !important;
        }
    }
}

@media (max-width: 900px) {
    h1 {
        font-size: 30px;
    }
}

@media (max-width: 600px) {
    h1 {
        font-size: 25px;
    }
}
